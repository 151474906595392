svg {
	pointer-events: none;
}

.drawings {
	position: relative;
	width: 100%;
  max-width: 600px;
  margin: auto;
	top: -100px;
}

.illustration {
	position: absolute;
	top: 50%;
	left: 50%;
	max-height: 100%;
	max-width: 100%;
	opacity: 0;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}

.illustration-inner {
	position: absolute;
	top: 50%;
	left: 50%;
	max-height: 100%;
	max-width: 100%;
	opacity: 0;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}

.line-drawing,
.illustration,
.illustration-inner {
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.line-drawing path {
	fill: none;
	stroke: black;
	stroke-width: 3;
}

.project-title {
  width: 100%;
  margin-top: -200px;
  padding-bottom: 10px;
}

.blurb {
  padding: 0 40px 40px 40px;
  width: 100%;
}

path.line-round {
	stroke-linecap: round;
}

path.stroke-medium {
	stroke-width: 2;
}

path.stroke-thin {
	stroke-width: 1;
}

.show {
	opacity: 1;
}

.hide {
	opacity: 0;
}

.carousel .slide {
	background: transparent;
}

.carousel a {
	position: relative;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  transition: ease all 0.25s;
  opacity: 0.4;
  filter: none;
  position: absolute;
  z-index: 2;
  top: 20px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer; }
	.carousel.carousel-slider .control-arrow:hover {
		background: transparent;
	}
  .carousel .control-arrow:hover {
		background: transparent;
    opacity: 0.8;
    filter: none; }
  .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    content: ''; }
  .carousel .control-disabled.control-arrow {
    opacity: 0;
    filter: none;
    cursor: inherit;
    display: none; }
  .carousel .control-prev.control-arrow {
    left: 0; }
    .carousel .control-prev.control-arrow:before {
      border-right: 16px solid black; }
  .carousel .control-next.control-arrow {
    right: 0; }
    .carousel .control-next.control-arrow:before {
      border-left: 16px solid black; }
.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.App .wrapper {
  width: 100%; 
  height: 100%;
  position: absolute; 
  display: table;
}

.App .title {
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
  font-size: 10vw;
  color: white;
  opacity: 0.7;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.Header {
  position: absolute;
  right: 20px;
  text-align: right;
  font-family: "Luckiest Guy", sans-serif;
  color: white;
  font-size: 24;
  top: 10px;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
}

.Header .button {
  float: right;
  opacity: 0.8;
  -webkit-transition: all ease .3s;
  transition: all ease .3s;
}

.Header .menu {
  padding-right: 2px;
  -webkit-animation-duration: .75s;
          animation-duration: .75s;
  cursor: pointer;
}

.Header.menu.item {
  cursor: pointer;
  opacity: 0.8;
  -webkit-transition: all ease .3s;
  transition: all ease .3s;
}

.Header .menu .item:hover {
  opacity: 1;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.HeaderLink .icon {
  border-radius: 50%;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: inline-block;
  height: 30px;
  margin-left: 8px;
  opacity: 0.8;
  overflow: hidden;
  -webkit-transition: all ease .3s;
  transition: all ease .3s;
  width: 30px;
}

.HeaderLink .icon:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  opacity: 1;
}
.tcon {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 40px;
  background: transparent;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent; }
  .tcon > * {
    display: block; }
  .tcon:hover,
  .tcon:focus {
    outline: none;
  }
  .tcon:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 1 !important;
  }
  .tcon::-moz-focus-inner {
    border: 0; }

.tcon-menu__lines {
  box-shadow: 2px 2px rgba(0, 0, 0, 0.5);
  display: inline-block;
  height: 5.71429px;
  width: 40px;
  border-radius: 2.85714px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: white;
  position: relative; }
  .tcon-menu__lines::before,
  .tcon-menu__lines::after {
    box-shadow: 2px 2px rgba(0, 0, 0, 0.5);
    display: inline-block;
    height: 5.71429px;
    width: 40px;
    border-radius: 2.85714px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    background: white;
    content: '';
    position: absolute;
    left: 0;
    -webkit-transform-origin: 2.85714px center;
    transform-origin: 2.85714px center;
    width: 100%; }
  .tcon-menu__lines::before {
    top: 10px; }
  .tcon-menu__lines::after {
    top: -10px; }
  .tcon-transform .tcon-menu__lines {
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8); }

.tcon-menu--xcross {
  width: auto; }
  .tcon-menu--xcross.tcon-transform .tcon-menu__lines {
    box-shadow: none;
    background: transparent; }
    .tcon-menu--xcross.tcon-transform .tcon-menu__lines::before,
    .tcon-menu--xcross.tcon-transform .tcon-menu__lines::after {
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      top: 0;
      width: 40px; }
    .tcon-menu--xcross.tcon-transform .tcon-menu__lines::before {
      -webkit-transform: rotate3d(0, 0, 1, 45deg);
      transform: rotate3d(0, 0, 1, 45deg); }
    .tcon-menu--xcross.tcon-transform .tcon-menu__lines::after {
      -webkit-transform: rotate3d(0, 0, 1, -45deg);
      transform: rotate3d(0, 0, 1, -45deg); }

.tcon-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .tcon-visuallyhidden:active,
  .tcon-visuallyhidden:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto; }

.Loader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0;
}

.Loader .inner {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  display: table;
}

.Loader .preloader {
  opacity: 0;
  text-align: center;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  display: table-cell;
  vertical-align: middle;
}
.Modal {
  position: absolute;
  width: 100%;
  height: 101%;
  left: 0;
  top: 0;
  padding: 0;
  overflow-y: auto;
}

.Modal .inner {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  display: table;
}

.Modal .preloader {
  opacity: 0;
  text-align: center;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  display: table-cell;
  vertical-align: middle;
}

.Modal .close {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
}
svg {
	pointer-events: none;
}

.drawings {
	position: relative;
	width: 100%;
  max-width: 600px;
  margin: auto;
	top: -100px;
}

.illustration {
	position: absolute;
	top: 50%;
	left: 50%;
	max-height: 100%;
	max-width: 100%;
	opacity: 0;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}

.illustration-inner {
	position: absolute;
	top: 50%;
	left: 50%;
	max-height: 100%;
	max-width: 100%;
	opacity: 0;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}

.line-drawing,
.illustration,
.illustration-inner {
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.line-drawing path {
	fill: none;
	stroke: black;
	stroke-width: 3;
}

.project-title {
  width: 100%;
  margin-top: -200px;
  padding-bottom: 10px;
}

.blurb {
  padding: 0 40px 40px 40px;
  width: 100%;
}

path.line-round {
	stroke-linecap: round;
}

path.stroke-medium {
	stroke-width: 2;
}

path.stroke-thin {
	stroke-width: 1;
}

.show {
	opacity: 1;
}

.hide {
	opacity: 0;
}

.carousel .slide {
	background: transparent;
}

.carousel a {
	position: relative;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  -webkit-transition: ease all 0.25s;
  transition: ease all 0.25s;
  opacity: 0.4;
  -webkit-filter: none;
          filter: none;
  position: absolute;
  z-index: 2;
  top: 20px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer; }
	.carousel.carousel-slider .control-arrow:hover {
		background: transparent;
	}
  .carousel .control-arrow:hover {
		background: transparent;
    opacity: 0.8;
    -webkit-filter: none;
            filter: none; }
  .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    content: ''; }
  .carousel .control-disabled.control-arrow {
    opacity: 0;
    -webkit-filter: none;
            filter: none;
    cursor: inherit;
    display: none; }
  .carousel .control-prev.control-arrow {
    left: 0; }
    .carousel .control-prev.control-arrow:before {
      border-right: 16px solid black; }
  .carousel .control-next.control-arrow {
    right: 0; }
    .carousel .control-next.control-arrow:before {
      border-left: 16px solid black; }
.Profile .profile {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0 -2px 0 3px rgba(0, 0, 0, 0.3) inset, 0 5px 5px rgba(0, 0, 0, 0.2), 0 15px rgba(0, 0, 0, 0.05) inset; 
  border: 1px solid #fff;
  border-radius: 50%;
  margin: auto;
}

.Profile .description {
  padding: 6px;
  font-family: 'Luckiest Guy', sans-serif;
  letter-spacing: 1.5px;
  font-size: 12px !important;
}

.Profile .profile-container {
  position: absolute;
  -webkit-transition: all ease .3s;
  transition: all ease .3s;
}

.Profile .profile-container:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.orbiter {
  width: 40px;
  height: 40px;
  top: 80px;
  left: 80px;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.rotate {
  -webkit-animation: rotate 4s infinite linear;
          animation: rotate 4s infinite linear;
}

.rotate-2 {
  -webkit-animation: rotate-2 4s infinite linear;
          animation: rotate-2 4s infinite linear;
}

.rotate-3 {
  -webkit-animation: rotate-3 4s infinite linear;
          animation: rotate-3 4s infinite linear;
}

.rotate-4 {
  -webkit-animation: rotate-4 4s infinite linear;
          animation: rotate-4 4s infinite linear;
}

.meteor {
  background-image: url('/images/medium.png');
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotateZ(0deg) translateX(130px);
            transform: rotateZ(0deg) translateX(130px)
  }
  100% {
    -webkit-transform: rotateZ(360deg) translateX(130px);
            transform: rotateZ(360deg) translateX(130px)
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotateZ(0deg) translateX(130px);
            transform: rotateZ(0deg) translateX(130px)
  }
  100% {
    -webkit-transform: rotateZ(360deg) translateX(130px);
            transform: rotateZ(360deg) translateX(130px)
  }
}

@-webkit-keyframes rotate-2 {
  0% {
    -webkit-transform: rotateZ(-180deg) translateX(130px);
            transform: rotateZ(-180deg) translateX(130px)
  }
  100% {
    -webkit-transform: rotateZ(180deg) translateX(130px);
            transform: rotateZ(180deg) translateX(130px)
  }
}

@keyframes rotate-2 {
  0% {
    -webkit-transform: rotateZ(-180deg) translateX(130px);
            transform: rotateZ(-180deg) translateX(130px)
  }
  100% {
    -webkit-transform: rotateZ(180deg) translateX(130px);
            transform: rotateZ(180deg) translateX(130px)
  }
}

@-webkit-keyframes rotate-3 {
  0% {
    -webkit-transform: rotateZ(-90deg) translateX(130px);
            transform: rotateZ(-90deg) translateX(130px)
  }
  100% {
    -webkit-transform: rotateZ(270deg) translateX(130px);
            transform: rotateZ(270deg) translateX(130px)
  }
}

@keyframes rotate-3 {
  0% {
    -webkit-transform: rotateZ(-90deg) translateX(130px);
            transform: rotateZ(-90deg) translateX(130px)
  }
  100% {
    -webkit-transform: rotateZ(270deg) translateX(130px);
            transform: rotateZ(270deg) translateX(130px)
  }
}

@-webkit-keyframes rotate-4 {
  0% {
    -webkit-transform: rotateZ(-270deg) translateX(130px);
            transform: rotateZ(-270deg) translateX(130px)
  }
  100% {
    -webkit-transform: rotateZ(90deg) translateX(130px);
            transform: rotateZ(90deg) translateX(130px)
  }
}

@keyframes rotate-4 {
  0% {
    -webkit-transform: rotateZ(-270deg) translateX(130px);
            transform: rotateZ(-270deg) translateX(130px)
  }
  100% {
    -webkit-transform: rotateZ(90deg) translateX(130px);
            transform: rotateZ(90deg) translateX(130px)
  }
}
.Contact .button {
  -webkit-transition: all ease .3s;
  transition: all ease .3s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100px;
  height: 100px;
  box-shadow: 0 -2px 0 3px rgba(0, 0, 0, 0.3) inset, 0 5px 5px rgba(0, 0, 0, 0.2), 0 15px rgba(0, 0, 0, 0.05) inset; 
  border: 3px solid #fff;
  border-radius: 50%;
  display: inline-block;
  margin: 40px;
}

.Contact .button:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.Contact .button.mobile {
  height: 60px;
  margin: 20px;
  width: 60px;
}
html, body {
  -webkit-tap-highlight-color: transparent;
}

body {
	background-color: rgba(22, 151, 152, 0.95);
	height: 100%;
	margin: 0;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, label, button {
	font-family: 'Luckiest Guy', sans-serif;
	letter-spacing: 1.5px;
}

h1 {
	font-size: 2.5em;
}

h2 {
	font-size: 2em;
}

.form-control {
	border: 2px inset;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	font-size: 16px;
	width: 100%;
}

.row {
	margin: 0;
}

.btn-primary {
	color: #fff;
	background-color: #337ab7;
	border-color: #2e6da4;
	display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.modified-bounceOut {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-name: modified-bounceOut;
  animation-name: modified-bounceOut;
}

@-webkit-keyframes modified-bounceOut {
  20% {
    visibility: visible;
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }
}

@keyframes modified-bounceOut {
  20% {
    visibility: visible;
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }
}

@-webkit-keyframes modified-jello {
  from {
    opacity: 0;
  }

  to {
    -webkit-transform: none;
    transform: none;
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes modified-jello {
  from {
    opacity: 0;
  }

  to {
    -webkit-transform: none;
    transform: none;
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.modified-jello-title {
  -webkit-animation-iteration-count: 1;
  -webkit-animation-name: modified-jello;
  -webkit-animation-timing-function: linear;
  -webkit-transform-origin: center;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
  animation-iteration-count: 1;
  animation-name: modified-jello;
  animation-timing-function: linear;
  transform-origin: center;
}

.modified-jello-enter {
  -webkit-animation-iteration-count: 1;
  -webkit-animation-name: modified-jello;
  -webkit-animation-timing-function: linear;
  -webkit-transform-origin: center;
  -webkit-animation-duration: .75s;
          animation-duration: .75s;
  animation-iteration-count: 1;
  animation-name: modified-jello;
  animation-timing-function: linear;
  transform-origin: center;
}

.modified-jello-leave {
  -webkit-animation-iteration-count: 1;
  -webkit-animation-name: modified-jello-out;
  -webkit-animation-timing-function: linear;
  -webkit-transform-origin: center;
  -webkit-animation-duration: .75s;
          animation-duration: .75s;
  animation-iteration-count: 1;
  animation-name: modified-jello-out;
  animation-timing-function: linear;
  transform-origin: center;
}

@-webkit-keyframes modified-jello-out {
  to, 100% {
    visibility: hidden;
    opacity: 0;
  }

  from {
    visibility: visible;
  }

  11.1% {
    -webkit-transform: none;
    transform: none;
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes modified-jello-out {
  to, 100% {
    visibility: hidden;
    opacity: 0;
  }

  from {
    visibility: visible;
  }

  from, 11.1% {
    -webkit-transform: none;
    transform: none;
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.modified-jello-out {
  -webkit-animation-name: modified-jello-out;
  animation-name: modified-jello-out;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes bounce {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
            animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(.9, .9, .9);
            transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(.97, .97, .97);
            transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

@keyframes bounce {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
            animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(.9, .9, .9);
            transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(.97, .97, .97);
            transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

.bounce {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  animation-name: bounce;
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

.bounce-appear {
  -webkit-animation-iteration-count: 1;
  -webkit-animation-name: bounce;
  -webkit-animation-timing-function: linear;
  -webkit-transform-origin: center;
  -webkit-animation-duration: .75s;
          animation-duration: .75s;
  animation-iteration-count: 1;
  animation-name: bounce;
  animation-timing-function: linear;
  transform-origin: center;
}

.bounce-enter {
  -webkit-animation-iteration-count: 1;
  -webkit-animation-name: bounce;
  -webkit-animation-timing-function: linear;
  -webkit-transform-origin: center;
  -webkit-animation-duration: .75s;
          animation-duration: .75s;
  animation-iteration-count: 1;
  animation-name: bounce;
  animation-timing-function: linear;
  transform-origin: center;
}

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.App .wrapper {
  width: 100%; 
  height: 100%;
  position: absolute; 
  display: table;
}

.App .title {
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
  font-size: 10vw;
  color: white;
  opacity: 0.7;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

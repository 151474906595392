.Contact .button {
  -webkit-transition: all ease .3s;
  transition: all ease .3s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100px;
  height: 100px;
  box-shadow: 0 -2px 0 3px rgba(0, 0, 0, 0.3) inset, 0 5px 5px rgba(0, 0, 0, 0.2), 0 15px rgba(0, 0, 0, 0.05) inset; 
  border: 3px solid #fff;
  border-radius: 50%;
  display: inline-block;
  margin: 40px;
}

.Contact .button:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.Contact .button.mobile {
  height: 60px;
  margin: 20px;
  width: 60px;
}
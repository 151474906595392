.Header {
  position: absolute;
  right: 20px;
  text-align: right;
  font-family: "Luckiest Guy", sans-serif;
  color: white;
  font-size: 24;
  top: 10px;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
}

.Header .button {
  float: right;
  opacity: 0.8;
  -webkit-transition: all ease .3s;
  transition: all ease .3s;
}

.Header .menu {
  padding-right: 2px;
  -webkit-animation-duration: .75s;
          animation-duration: .75s;
  cursor: pointer;
}

.Header.menu.item {
  cursor: pointer;
  opacity: 0.8;
  -webkit-transition: all ease .3s;
  transition: all ease .3s;
}

.Header .menu .item:hover {
  opacity: 1;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.HeaderLink .icon {
  border-radius: 50%;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: inline-block;
  height: 30px;
  margin-left: 8px;
  opacity: 0.8;
  overflow: hidden;
  -webkit-transition: all ease .3s;
  transition: all ease .3s;
  width: 30px;
}

.HeaderLink .icon:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  opacity: 1;
}
.Loader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0;
}

.Loader .inner {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  display: table;
}

.Loader .preloader {
  opacity: 0;
  text-align: center;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  display: table-cell;
  vertical-align: middle;
}
html, body {
  -webkit-tap-highlight-color: transparent;
}

body {
	background-color: rgba(22, 151, 152, 0.95);
	height: 100%;
	margin: 0;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, label, button {
	font-family: 'Luckiest Guy', sans-serif;
	letter-spacing: 1.5px;
}

h1 {
	font-size: 2.5em;
}

h2 {
	font-size: 2em;
}

.form-control {
	border: 2px inset;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	font-size: 16px;
	width: 100%;
}

.row {
	margin: 0;
}

.btn-primary {
	color: #fff;
	background-color: #337ab7;
	border-color: #2e6da4;
	display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.modified-bounceOut {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-name: modified-bounceOut;
  animation-name: modified-bounceOut;
}

@-webkit-keyframes modified-bounceOut {
  20% {
    visibility: visible;
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }
}

@keyframes modified-bounceOut {
  20% {
    visibility: visible;
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }
}

@-webkit-keyframes modified-jello {
  from {
    opacity: 0;
  }

  to {
    -webkit-transform: none;
    transform: none;
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes modified-jello {
  from {
    opacity: 0;
  }

  to {
    -webkit-transform: none;
    transform: none;
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.modified-jello-title {
  -webkit-animation-iteration-count: 1;
  -webkit-animation-name: modified-jello;
  -webkit-animation-timing-function: linear;
  -webkit-transform-origin: center;
  animation-duration: 10s;
  animation-iteration-count: 1;
  animation-name: modified-jello;
  animation-timing-function: linear;
  transform-origin: center;
}

.modified-jello-enter {
  -webkit-animation-iteration-count: 1;
  -webkit-animation-name: modified-jello;
  -webkit-animation-timing-function: linear;
  -webkit-transform-origin: center;
  animation-duration: .75s;
  animation-iteration-count: 1;
  animation-name: modified-jello;
  animation-timing-function: linear;
  transform-origin: center;
}

.modified-jello-leave {
  -webkit-animation-iteration-count: 1;
  -webkit-animation-name: modified-jello-out;
  -webkit-animation-timing-function: linear;
  -webkit-transform-origin: center;
  animation-duration: .75s;
  animation-iteration-count: 1;
  animation-name: modified-jello-out;
  animation-timing-function: linear;
  transform-origin: center;
}

@-webkit-keyframes modified-jello-out {
  to, 100% {
    visibility: hidden;
    opacity: 0;
  }

  from {
    visibility: visible;
  }

  11.1% {
    -webkit-transform: none;
    transform: none;
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes modified-jello-out {
  to, 100% {
    visibility: hidden;
    opacity: 0;
  }

  from {
    visibility: visible;
  }

  from, 11.1% {
    -webkit-transform: none;
    transform: none;
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.modified-jello-out {
  -webkit-animation-name: modified-jello-out;
  animation-name: modified-jello-out;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@keyframes bounce {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounce {
  animation-fill-mode: both;
  animation-name: bounce;
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

.bounce-appear {
  -webkit-animation-iteration-count: 1;
  -webkit-animation-name: bounce;
  -webkit-animation-timing-function: linear;
  -webkit-transform-origin: center;
  animation-duration: .75s;
  animation-iteration-count: 1;
  animation-name: bounce;
  animation-timing-function: linear;
  transform-origin: center;
}

.bounce-enter {
  -webkit-animation-iteration-count: 1;
  -webkit-animation-name: bounce;
  -webkit-animation-timing-function: linear;
  -webkit-transform-origin: center;
  animation-duration: .75s;
  animation-iteration-count: 1;
  animation-name: bounce;
  animation-timing-function: linear;
  transform-origin: center;
}
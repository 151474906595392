.Profile .profile {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0 -2px 0 3px rgba(0, 0, 0, 0.3) inset, 0 5px 5px rgba(0, 0, 0, 0.2), 0 15px rgba(0, 0, 0, 0.05) inset; 
  border: 1px solid #fff;
  border-radius: 50%;
  margin: auto;
}

.Profile .description {
  padding: 6px;
  font-family: 'Luckiest Guy', sans-serif;
  letter-spacing: 1.5px;
  font-size: 12px !important;
}

.Profile .profile-container {
  position: absolute;
  transition: all ease .3s;
}

.Profile .profile-container:hover {
  transform: scale(1.2);
}

.orbiter {
  width: 40px;
  height: 40px;
  top: 80px;
  left: 80px;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.rotate {
  animation: rotate 4s infinite linear;
}

.rotate-2 {
  animation: rotate-2 4s infinite linear;
}

.rotate-3 {
  animation: rotate-3 4s infinite linear;
}

.rotate-4 {
  animation: rotate-4 4s infinite linear;
}

.meteor {
  background-image: url('/images/medium.png');
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg) translateX(130px)
  }
  100% {
    transform: rotateZ(360deg) translateX(130px)
  }
}

@keyframes rotate-2 {
  0% {
    transform: rotateZ(-180deg) translateX(130px)
  }
  100% {
    transform: rotateZ(180deg) translateX(130px)
  }
}

@keyframes rotate-3 {
  0% {
    transform: rotateZ(-90deg) translateX(130px)
  }
  100% {
    transform: rotateZ(270deg) translateX(130px)
  }
}

@keyframes rotate-4 {
  0% {
    transform: rotateZ(-270deg) translateX(130px)
  }
  100% {
    transform: rotateZ(90deg) translateX(130px)
  }
}
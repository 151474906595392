.Modal {
  position: absolute;
  width: 100%;
  height: 101%;
  left: 0;
  top: 0;
  padding: 0;
  overflow-y: auto;
}

.Modal .inner {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  display: table;
}

.Modal .preloader {
  opacity: 0;
  text-align: center;
  transition: all ease 0.3s;
  display: table-cell;
  vertical-align: middle;
}

.Modal .close {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
}